import React, { useRef, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Typography, Link, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Section, SubSection, SubSubSection } from "./components/Sections";
import { DescriptionButton } from "./components/Buttons";
import { Strong } from "./components/Utilies";
import { subscribe_link } from "./Data";
import YouTube from 'react-youtube';
import { Title } from "./components/Titles";
import { HashLink } from "react-router-hash-link";

export default function Landing(props) {
    const theme = useTheme();
    const ref = useRef(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(ref.current.offsetWidth)
    }, []);

    const videoWidth = Math.min(width, 700)
    const opts = {
        height: videoWidth / 1920 * 1080,
        width: videoWidth,
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <Box ref={ref} margin={theme.spacing(0, 0, 8)}>
            <Box margin={theme.spacing(8, "auto", 1)}>
                <img src="logo-color.png" style={{width: "40%", textAlign: "left"}} />
            </Box>
            <Box margin={theme.spacing(1, "auto", 6)}>
                <Typography
                    variant={
                        useMediaQuery(theme.breakpoints.up("sm")) ? "h4" : "h5"
                    }
                    color="textPrimary"
                >
                    <strong>M</strong>ulti<strong>L</strong>ingual <strong>S</strong>peech processing 
                </Typography>
                <Typography
                    variant={
                        useMediaQuery(theme.breakpoints.up("sm")) ? "h4" : "h5"
                    }
                    color="textPrimary"
                >
                    <strong>U</strong>
                    niversal <strong>PER</strong>formance <strong>B</strong>enchmark
                </Typography>
            </Box>
            <Box maxWidth={800} margin={theme.spacing(1, "auto", 6)}>
                <Typography variant="h6" color="textPrimary">
                    <strong>Interspeech 2025  ML-SUPERB 2.0 Challenge Timeline</strong>
                </Typography>
                <span align="left">
                <HashLink to="/challenge-interspeech2025/challenge_overview#top">Challenge Policy</HashLink>&nbsp;&nbsp;&nbsp;
                <ul>
                    <li>
                        <span><Strong>November 25, 2024</Strong>: Challenge announcement</span>
                    </li>
                    <li><Strong>December 4, 2024</Strong>: Leaderboard
                        <span> is online and accepting submissions</span>
                    </li>
                    <li><span><Strong><s>February 10</s> February 12 11:59 AM AoE, 2025</Strong>: </span><span> Final Ranking Announced</span></li>
                    <li><span><Strong>February 12 11:59 PM AoE, 2025</Strong>: </span><span> Paper Submission Deadline</span></li>
                </ul>
            </span>
            </Box>
            <Box maxWidth={800} margin="auto" textAlign="left">
                <SubSection>
                    <SubSubSection>
                        <Typography
                            component={"span"}
                            variant="body1"
                            color="textSecondary"
                        >
                            Multilingual SUPERB (ML-SUPERB) is an extension of the <a href={"https://superbbenchmark.org/"} target="_blank" rel="noopener noreferrer">SUPERB benchmark</a>, 
                            designed to evaluate the cross-lingual capabilities of speech representation learning. For this year's challenge, our focus is to encourage the development of
                            state-of-the-art ASR systems for all languages and language varieties.
                            
                            
                            The ML-SUPERB 2.0 Challenge has three main themes:
                            
                            <div
                                style={{
                                    width: "fit-content",
                                    margin: "auto",
                                    textAlign: "left",
                                }}
                            >
                                <ol>
                                    <li>
                                    <Strong>Flexibility</Strong>: Participants are allowed to use almost any algorithm, dataset, or pre-trained model. We hope this encourages
                                    creative applications of the latest pre-trained models or modelling techniques.
                                    </li>
                                    <li>
                                    <Strong>Robustness across Languages</Strong>: Models will also be scored in consistency and fairness, encouraging improvements in performance
                                    across all languages.
                                    </li>
                                    <li>
                                    <Strong>Robustness across Language Varieties</Strong>: Models will be evaluated on a hidden set containing 200+ language variations, such as non-standard
                                    accents and dialects.
                                    </li>
                                </ol>
                            </div>

                            We are partnering with <a href={"https://dynabench.org/tasks/ml-superb"} target="_blank" rel="noopener noreferrer">DynaBench</a> to host a live leaderboard and online evaluation
                            server. Inference and scoring will automatically be performed on the server, which is slated to open on December 4th. 
                            For more details about the challenge, please refer to <a href={"/challenge-interspeech2025/challenge_overview#top"} target="_blank" rel="noopener noreferrer">the overview page</a>.
                        </Typography>
                    </SubSubSection>
                
                    <SubSubSection>
                        <Typography variant="body1" color="textSecondary">
                            Further details about the overall SUPERB project can be found <a href={"https://superbbenchmark.org/"} target="_blank" rel="noopener noreferrer">here</a>.
                        </Typography>
                    </SubSubSection>
                </SubSection>
            </Box>
            <Box maxWidth={800} margin="auto" textAlign="left">
                <SubSection>
                    <Grid container justify="space-evenly" spacing={0}>
                        {[
                            ["ntu-1000.png", "https://www.ntu.edu.tw/english/"],
                            ["cmu-1000.png", "https://www.cmu.edu/"],
                            ["stanford.png", "https://www.rembrand.com/"],
                            ["ttic.png", "https://www.rembrand.com/"],
                            ["gmu.png", "https://www.rembrand.com/"],
                        ].map((filename) => {
                            return (
                                <Grid item xs={6} sm={4} md={6} key={filename[0]}>
                                    <a target="_blank" href={filename[1]}>
                                        <img src={filename[0]} width="100%" />
                                    </a>
                                </Grid>
                            );
                        })}
                    </Grid>
                </SubSection>
                <Box margin={theme.spacing(8, 0)} textAlign="center">
                    <Title title="Acknowledgement"/>
                    <Typography variant="body1" color="textSecondary">
                        We thank <DescriptionButton name={<a>Shu-wen Yang</a>} link="https://github.com/leo19941227" /> and <DescriptionButton name={<a>Ming-Yang Ho</a>} link="https://kaminyou.com/" /> for creating and maintaining the SUPERB official website.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
